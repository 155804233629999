import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  VStack,
  HStack,
  Select,
  useToast,
  Button,
  ButtonProps,
  Spinner,
} from '@chakra-ui/react';
import axios from 'axios';
import GradientButton from './GradientButton'; // Ajusta la ruta según tu estructura

interface JoinTokenMeetButtonProps extends ButtonProps {}

const JoinTokenMeetButton: React.FC<JoinTokenMeetButtonProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formValues, setFormValues] = useState({
    name: '',
    profession: '',
    city: '',
    country: '',
    email: '',
    wallet: '',
  });
  const [isLoading, setIsLoading] = useState(false); // Estado para el indicador de carga
  const toast = useToast();

  // Verificación básica para dirección ETH
  const isEthAddress = (address: string) => /^0x[a-fA-F0-9]{40}$/.test(address);

  // Manejo de cambios en los campos del formulario
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  // Manejo del envío del formulario
  const handleSubmit = async () => {
    const { name, profession, city, country, email, wallet } = formValues;

    if (!name || !profession || !city || !country || !email) {
      toast({
        title: 'Error',
        description: 'Por favor, completa todos los campos obligatorios.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (wallet && !isEthAddress(wallet)) {
      toast({
        title: 'Error',
        description: 'La dirección de la wallet no es válida.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true); // Activa el estado de carga antes de enviar la solicitud

    try {
      await axios.post('https://tokenmeet.vercel.app/api/join-token-meet',
       { name, profession, city, country, email, wallet });
      toast({
        title: 'Solicitud enviada',
        description: 'Gracias por unirte a TokenMeet. ¡Nos pondremos en contacto pronto!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      const errorMessage = (error as any).response?.data?.message || 'Hubo un problema al enviar la información. Intenta de nuevo más tarde.';

      if (errorMessage === 'El correo ya está registrado.') {
        // Si el correo ya está registrado, mostramos un mensaje de advertencia
        toast({
          title: 'Error',
          description: 'Este correo ya está registrado en el sistema.',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Error al enviar el formulario.',
          description: errorMessage,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } finally {
      setIsLoading(false); // Desactiva el estado de carga al terminar la solicitud
    }
  };

  return (
    <>
      <GradientButton onClick={onOpen} {...props}>
        Únete a TokenMeet
      </GradientButton>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="gray.800" color="white" borderRadius="md">
          <ModalHeader fontSize="2xl" fontWeight="bold" textAlign="center">
            Únete a TokenMeet
          </ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl id="name" isRequired>
                <FormLabel>Nombre y Apellidos</FormLabel>
                <Input
                  name="name"
                  value={formValues.name}
                  onChange={handleInputChange}
                  placeholder="Nombre y Apellidos"
                  bg="gray.700"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'gray.500' }}
                  focusBorderColor="blue.500"
                />
              </FormControl>

              <FormControl id="profession" isRequired>
                <FormLabel>Profesión</FormLabel>
                <Input
                  name="profession"
                  value={formValues.profession}
                  onChange={handleInputChange}
                  placeholder="Profesión"
                  bg="gray.700"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'gray.500' }}
                  focusBorderColor="blue.500"
                />
              </FormControl>

              <HStack spacing={4}>
                <FormControl id="city" isRequired>
                  <FormLabel>Población</FormLabel>
                  <Input
                    name="city"
                    value={formValues.city}
                    onChange={handleInputChange}
                    placeholder="Población"
                    bg="gray.700"
                    borderColor="gray.600"
                    _hover={{ borderColor: 'gray.500' }}
                    focusBorderColor="blue.500"
                  />
                </FormControl>

                <FormControl id="country" isRequired>
                  <FormLabel>País</FormLabel>
                  <Select
                    name="country"
                    value={formValues.country}
                    onChange={handleInputChange}
                    placeholder="Seleccionar"
                    bg="gray.700"
                    borderColor="gray.600"
                    _hover={{ borderColor: 'gray.500' }}
                    focusBorderColor="blue.500"
                  >
                    <option value="España">España</option>
                    <option value="México">México</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Colombia">Colombia</option>
                  </Select>
                </FormControl>
              </HStack>

              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={formValues.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                  bg="gray.700"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'gray.500' }}
                  focusBorderColor="blue.500"
                />
              </FormControl>

              <FormControl id="wallet">
                <FormLabel>Wallet</FormLabel>
                <Input
                  name="wallet"
                  value={formValues.wallet}
                  onChange={handleInputChange}
                  placeholder="Únete y te enviaremos nuestro NFT"
                  bg="gray.700"
                  borderColor="gray.600"
                  _hover={{ borderColor: 'gray.500' }}
                  focusBorderColor="blue.500"
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button variant="outline" colorScheme="red" onClick={onClose}>
              Cerrar
            </Button>
            <GradientButton onClick={handleSubmit} isLoading={isLoading}>
              {isLoading ? <Spinner size="sm" /> : 'Enviar'}
            </GradientButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default JoinTokenMeetButton;
